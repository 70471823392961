import { redisGetHandler } from "../utils/redis";

export default (axios: any, config: any) => ({
  async getPoints(cityName: string, regionName = "", kladrId = "") {
    const { data } = await axios.post(
      `${config.API_URL}/edost/getPoints?city_name_new=${cityName}&region_name=${regionName}&kladr_id=${kladrId}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
  async getCalc(Redis: any, params: any, reset = false) {
    const req = Object.entries({
      ...params,
      city_name_new: encodeURIComponent(params.city_name_new),
    })
      .map((params) => params.join(`=`))
      .join(`&`);
    return redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/edost/getCalcNewNew?${req}`,
      reset,
    );
  },
  async getMinDays(Redis = false, cityName: string, kladr: string) {
    return redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/edost/getMinDays?cityName=${encodeURIComponent(cityName)}&kladr=${kladr}`,
    );
  },
  async getPointsOld(cityName: string, regionName = "") {
    const { data } = await axios.get(
      `${config.API_URL}/edost/getPointsOld?city_name=${cityName}&region_name=${regionName}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
});
