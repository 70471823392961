import { redisGetHandler } from "../utils/redis";

export default (axios, config) => ({
  async getMainSlider(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getMainBanner",
      reset,
    );
  },
  async getMainSections(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getMainSections",
      reset,
    );
  },
  async getMainAdv(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getMainAdv",
      reset,
    );
  },
  async getModels(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getModels",
      reset,
    );
  },
  async getMainProduction(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getMainProduction",
      reset,
    );
  },
  async getMainNews(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getMainNews",
      reset,
    );
  },
  async getNews(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getNews",
      reset,
    );
  },
  async getProduction(Redis, pageSize = 1, pageNum = 20, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/baltex/getProduction?pageSize=${pageSize}&pageNum=${pageNum}`,
      reset,
    );
  },
  async getNewsDetail(Redis, code, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/baltex/getNewsDetail?code=${code}`,
      reset,
    );
  },
  async getProductionDetail(Redis, code, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/baltex/getProductionDetail?code=${code}`,
      reset,
    );
  },
  async getAboutContent(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/baltex/getAboutContent`,
      reset,
    );
  },
  async getMeta(Redis, page, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/baltex/getMeta?&page=${page}`,
      reset,
    );
  },
  async getMainNumbers(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getMainNumbers",
      reset,
    );
  },
  async getScripts(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getScripts",
      reset,
    );
  },
  async getReviews(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/baltex/getReviews",
      reset,
    );
  },
});
