export default (axios, config) => ({
  async getRelocations() {
    const { data } = await axios.get(
      `https://admin.farkop.ru/bxApi/warehouses/getRelocationsNew`,
    );
    return data;
  },
  async getProductStoks(ids) {
    const { data } = await axios.get(
      `https://admin.farkop.ru/bxApi/warehouses/getProductStoks?ids=${ids}`,
    );
    return data;
  },
  async getBlockStores() {
    const { data } = await axios.get(
      `https://admin.farkop.ru/bxApi/warehouses/getBlockStoresNew`,
    );
    return data;
  },
  async getServerTime() {
    const { data } = await axios.get(
      `https://admin.farkop.ru/bxApi/warehouses/getServerTime`,
    );
    return data;
  },
});
