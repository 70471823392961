import { redisGetHandler } from "../utils/redis";

export default (axios, config) => ({
  async getServicesNew(Redis, reset = false) {
    return redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/services/getServicesNew",
      reset,
    );
  },
  async getServices(Redis, reset = false) {
    return redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/services/getServices",
      reset,
    );
  },
  async getServicesInstall(Redis, code, reset = false) {
    return redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/services/getServicesInstall?code=${code}`,
      reset,
    );
  },
});
