import { redisGetHandler } from "../utils/redis";

export default (axios, config) => ({
  async getCityList(q) {
    const { data } = await axios.get(
      `${config.API_URL}/location/getCityList?q=${q}`,
    );
    return data;
  },
  async getShops(Redis = false, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/location/getShops",
      reset,
    );
  },
  async getContacts() {
    const { data } = await axios.get(`${config.API_URL}/location/getContacts`);
    return data;
  },
  async getDomains(Redis = false, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/location/getDomains",
      reset,
    );
  },
});
