import { redisGetHandler } from "../utils/redis";

export default (axios, config) => ({
  async getProps(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/props/getProps`,
      reset,
    );
  },
});
