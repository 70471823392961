export default (axios, config) => ({
  async checkUser(phone) {
    const { data } = await axios.post(
      `${config.API_URL}/auth/checkUser?phone=${phone}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
  async auth({ phone, code }) {
    const { data } = await axios.post(
      `${config.API_URL}/auth/auth?phone=${phone}&code=${code}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
  async getCode(phone) {
    const { data } = await axios.post(
      `${config.API_URL}/auth/getCode?phone=${phone}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
  async getUserCryptoId() {
    const { data } = await axios.post(
      `${config.API_URL}/auth/getUserCryptoId`,
      {},
      { withCredentials: true },
    );
    return data;
  },
  async authByCryptoId(code) {
    const { data } = await axios.post(
      `${config.API_URL}/auth/authByCryptoId?code=${code}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
});
