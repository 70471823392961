import { toQueryString } from "../utils";
import { redisGetHandler } from "../utils/redis";

export default (axios, config) => ({
  async getNews(Redis, params, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/news/getNews?${toQueryString(params)}`,
      reset,
    );
  },
  async getNewsAll(Redis, sectionId, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/news/getNewsAll?sectionId=${sectionId}`,
      reset,
    );
  },
  async fetchSections(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/news/getSections`,
      reset,
    );
  },
  async getNewsDetail(Redis, code, sectionId, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/news/getNewsDetail?code=${code}`,
      reset,
    );
  },
});
