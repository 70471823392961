import { redisGetHandler } from "../utils/redis";

export default (axios, config) => ({
  async getAllBrands(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/brands/getAllBrands",
      reset,
    );
  },
  async getBrands(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/brands/getBrands",
      reset,
    );
  },
  async getBrand(Redis, code, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/brands/getBrand?code=${code}`,
      reset,
    );
  },
});
