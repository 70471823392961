import { redisGetHandler } from "~/utils/redis";

export default (axios: any, config: any) => ({
  async getPoints(Redis: any, cityName = "Москва", reset = false) {
    return redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/sdek/getPoints?cityName=${encodeURIComponent(cityName)}`,
      reset,
    );
  },
});
