export default (axios, config) => ({
  async detectCity() {
    const { data } = await axios.get(
      `${config.API_URL}/dadata/detectCityNew`,
      {},
      { withCredentials: true },
    );
    return data;
  },
});
