import type {
  DeliveryListResponse,
  PaymentListResponse,
} from "../types/api/cart";
import { redisGetHandler } from "../utils/redis";

export default (axios: any, config: any) => ({
  async getDeliveryList(
    Redis: any,
    reset = false,
  ): Promise<DeliveryListResponse> {
    return redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/cart_v2/getDeliveryList`,
      reset,
    );
  },
  async getPaymentList(
    Redis: any,
    reset = false,
  ): Promise<PaymentListResponse> {
    return redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/cart_v2/getPaymentList`,
      reset,
    );
  },
  async initOrder(params: any) {
    const req = Object.entries(params)
      .map((params) => params.join(`=`))
      .join(`&`);
    const data = await axios.post(
      `${config.API_URL}/cart_v2/initOrderAuth?${req}`,
      {},
      { withCredentials: true },
    );
    return data.data;
  },
  async addCoupon(coupon: string) {
    const { data } = await axios.get(
      `${config.API_URL}/cart_v2/addCoupon?coupon=${coupon}`,
      { withCredentials: true },
    );
    return data;
  },
  async removeCoupon(coupon: string) {
    const { data } = await axios.get(
      `${config.API_URL}/cart_v2/removeCoupon?coupon=${coupon}`,
      { withCredentials: true },
    );
    return data;
  },
});
