import { redisGetHandler } from "../utils/redis";

export default (axios, config) => ({
  async getTopMarks(Redis, reset = false) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      "/models/getTopMarks",
      reset,
    );
  },
  async getMarksNew(Redis, sectionId, reset) {
    return await redisGetHandler(
      Redis,
      axios,
      config.API_URL,
      `/models/getMarksNew?sectionId=${sectionId}`,
      reset,
    );
  },
});
