import axios from "axios";

import baltex from "~/api/baltex";
import catalogSeo from "~/api/catalogSeo";
import dadata from "~/api/dadata";
import lendings from "~/api/lendings";
import server from "~/api/server";
import warehouses from "~/api/warehouses";

import auth from "./auth";
import basket from "./basket";
import brands from "./brands";
import cart from "./cart";
import catalog from "./catalog";
import content from "./content";
import delivery from "./delivery";
import edost from "./edost";
import forms from "./forms";
import location from "./location";
import models from "./models";
import news from "./news";
import orders from "./orders";
import photo from "./photo";
import product from "./product";
import props from "./props";
import sdek from "./sdek";
import sections from "./sections";
import services from "./services";
import sitemap from "./sitemap";
import user from "./user";

let API_URL = "";
let API_SERVER_URL = "";
if (typeof window === "undefined") {
  API_URL = process.env.API_URL as string;
  API_SERVER_URL = process.env.API_SERVER_URL as string;
} else if (window.location.host.includes("farkop.ru")) {
  API_URL = "https://farkop.ru/bxApi";
  API_SERVER_URL = "/api";
} else {
  API_URL = "/bxApi";
  API_SERVER_URL = "/api";
}

const config = {
  API_URL,
  API_SERVER_URL,
};

export const Api = {
  catalog: catalog(axios, config),
  catalogSeo: catalogSeo(axios, config),
  product: product(axios, config),
  models: models(axios, config),
  auth: auth(axios, config),
  forms: forms(axios, config),
  location: location(axios, config),
  user: user(axios, config),
  content: content(axios, config),
  news: news(axios, config),
  cart: cart(axios, config),
  basket: basket(axios, config),
  delivery: delivery(axios, config),
  services: services(axios, config),
  brands: brands(axios, config),
  orders: orders(axios, config),
  sections: sections(axios, config),
  photo: photo(axios, config),
  props: props(axios, config),
  sdek: sdek(axios, config),
  edost: edost(axios, config),
  sitemap: sitemap(axios, config),
  server: server(axios, config),
  dadata: dadata(axios, config),
  baltex: baltex(axios, config),
  lendings: lendings(axios, config),
  warehouses: warehouses(axios, config),
};
